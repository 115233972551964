<template>
    <Card title="消息配置">
        <div class="item " v-for="item in list" :key="item.id">
            <div class="title">
                <DataDictFinder dictType="typeCode" :dictValue="item.typeCode" />
            </div>
            <div class="value">

                <a-checkbox-group v-model="item.list">
                    <a-checkbox v-for="element in messageRange" :key="element.value" :value="element.value">
                        {{ element.name }}

                    </a-checkbox>
                </a-checkbox-group>

            </div>


        </div>

        <div class="center">
            <a-button type="primary" :loading="loading" @click="save">保存</a-button>
        </div>
    </Card>
</template>
  
<script>
import request from '@/api/request'

import { mapGetters } from "vuex";

function fetchList() {
    return request({
        url: '/basic-service/message/range/list',

    })
}

function update(data) {
    return request({
        url: '/basic-service/message/range/update',
        method: 'post',
        data,
    })
}

export default {
    data() {
        return {
            list: [],

            loading: false,
        };
    },

    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        messageRange() {
            return this.findDataDict("messageRange");
        },
    },

    mounted() {
        this.getList();
    },

    methods: {
        getList() {

            const hide = this.$message.loading('加载中...')
            fetchList()
                .then((res) => {
                    if (Array.isArray(res)) {
                        this.list = res.map(item => {
                            return {
                                ...item,
                                list: typeof item.msgRange === 'string' ? item.msgRange.split(',') : []

                            }
                        })
                    }
                })
                .finally(() => {
                    hide();
                });
        },

        save(item) {
            console.log('item', item)
            this.loading = true;
            update(this.list.map(item => {
                return {
                    id: item.id,
                    msgRange: item.list.join(','),
                    typeCode: item.typeCode,
                }
            })).finally(() => {
                this.loading = false;
            })
        }

    },
};
</script>
  
  
<style lang="less" scoped>
.item {
    border-bottom: 1px solid #f0f0f0;
    padding: 2vh;
    margin-bottom: 2vh;

    .title {
        font-weight: bold;
        color: var(--theme-color);
        margin-bottom: 1vh;
    }


}
</style>
  